import * as React from "react";
import {AttachFileRounded} from "@mui/icons-material";

export const getLink = (guid, token, type) => {
	let url;
	if (token && type) {
		url = '/download/?token=' + token + '&type=' + type;
	} else {
		url = '/download/?guid=' + guid;
	}
	if (process.env.NODE_ENV !== 'production') {
		return 'http://127.0.0.1:8080' + url;
	}
	return url;
}

export const renderFile = (a/*: JsonFile*/) => {
	return <a href={getLink(a.guid)} rel="noreferrer" target={'_blank'}>
		<AttachFileRounded sx={{fontSize: '100%', marginBottom: '-2px'}}/> {a.fileName} ({Math.round((a.fileSize || 0) / 1024)} kB)</a>;
}

export const renderPaymentConfirmationFile = (token, year) => {
	return <a href={getLink(undefined, token, 'paymentConfirmation')} rel="noreferrer" target={'_blank'}>
		<AttachFileRounded sx={{fontSize: '100%', marginBottom: '-2px'}}/> Potvrzení příjmy {year}.pdf</a>;
}
